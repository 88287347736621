ul.tags {
    /* https://www.w3schools.com/css/css_list.asp */
    display: inline;
    /* align-items: flex-start; */
    list-style-type: none;
    /* no bullets */
    margin: 0;
    padding: 0;
}

ul.tags li {
    list-style: none;
    /* display: inline; */
}

/*
Rounding Corners ... 
https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius 
*/

li.tag {
    color: rgb(45, 54, 54);
    background-color: #045de9;
    background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
    border: none;
    padding: 0.5em 0.75em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    font-family: 'Montserrat', sans-serif;
    border-radius: 1em;
    margin: 0.25em;
    transition: 0.25s;
    /* transition-timing-function: cubic-bezier(0,1.68,1,-0.73); */
    transition-timing-function: ease-in-out;
}

li.tag:hover {
    text-decoration: bold;
    background-color: #7807fa75;
    /* background-image: url("images/sacred-pattern-inverted.svg"); */
    color: #000;
    text-decoration: bold;
    border-radius: 0em;
}

li.py {
    color: #FFF;
    background-color: #FCD000;
    background-image: linear-gradient(135deg, #41B129 0%, #f9bf00 100%);
}

li.js {
    color: #FCDC00;
    background-color: #000;
    background-image: linear-gradient(315deg, #000 0%, rgb(36, 36, 39) 74%);
}

li.ts {
    color: #FFF;
    background-image: linear-gradient(315deg, #01273F 0%, #3078C6 100%);
}

li.osx {
    color: #515151;
    background-image: linear-gradient(315deg, #F7F7F7 0%, #F7F7F7 74%);
}

li.scala {
    color: #859904;
    background-image: linear-gradient(315deg, #DC322F 0%, #7F0C1E 100%);
}

li.java {
    color: #FFF;
    background-image: radial-gradient(#E80302 0%, #690100 100%);
}

li.haskell {
    color: #FFF;
    background-image: linear-gradient(315deg, #5E5185 0%, #8E4E8B 100%);
}

li.html {
    color: #EBEBEB;
    background-image: linear-gradient(90deg, #E44D26 0%, #F06629 100%);
}

li.css {
    color: #EBEBEB;
    background-image: linear-gradient(90deg, #284DE4 0%, #2965F0 100%);
}

li.go {
    color: #1F2325;
    background-image: linear-gradient(315deg, #0ec1e9 0%, #7ED6E9 100%);
}

li.c {
    color: #FFF;
    background-image: linear-gradient(315deg, #659BD2 0%, #014383 100%);
}

li.tinkering {
    border-style: dashed !important;
    border-color: goldenrod !important;
    border-width: 0.1px;
    color: #000;
    background-image: linear-gradient(315deg, #D7E1EC 0%, #FFFFFF 100%);
    opacity: 0.25;
}