/* 
  https://reactjs.org/tutorial/tutorial.html
  https://varya.me/en/posts/pseudo-tag-cloud-css/ 
  https://blog.prototypr.io/top-10-ui-fonts-for-web-mobile-a8488e561ce3 
*/
/* 
  I shouldnt set height on flex items ... flex figures it out ...!
  https://css-tricks.com/snippets/css/a-guide-to-flexbox/
  https://stackoverflow.com/questions/34365528/why-is-align-self-stretch-not-working-on-a-flex-item?rq=1 
*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ... Layout Mechanics ... */

.MainArea {
  margin-top: 5%;
  /* margin-bottom: 5%; */
  margin-left: 10%;
  margin-right: 10%;
  /* padding-bottom: 5%; */
}

.ContentArea {
  background-color: #00000013;
  background-image: url("../images/sacred-pattern.svg");
  background-repeat: repeat;
  width: 65%;
  gap: 7.5%;

}

.SideArea {
  background-color: #00000013;
  background-image: url("../images/sacred-pattern-inverted.svg");
  background-repeat: repeat;
  background-position: 20 0;
  width: 15%;
  /* align-items: top; */
  justify-content: center;
}

/* div.FlexElement {
  justify-content: center;
} */

.LI-profile-badge {
  display: inline;
  /* align-self: flex-start; */
}

.GithubStats {
  display: inline;
  /* align-self: flex-start; */
}

/* ... Flex Mechanics ... */
.FlexContainer {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid black;
  box-shadow: 25px 25px 25px #888888;
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing */
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_flexible_box_layout/Aligning_items_in_a_flex_container */
.FlexVerticalContainer {
  display: flex;
  align-items: center; /* center items in column */
  justify-content: start; /* start content at top of column*/
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid black;
  box-shadow: 25px 25px 25px #888888;
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing */
}

.FlexElement {
  flex: 1 1 auto;
  flex-basis: auto;
}

/* Flex Elements in the Content Area ...*/
.ContentArea h1 {
  padding-bottom: 0.5%;
  border-bottom: 5px solid white;
  flex-basis: 100%;
  /* H1's should take the whole row ... */
}

/* H2's and Lists should take same space ... */
.ContentArea h2,
.ContentArea ul,
.ContentArea .FlexElement {
  flex-basis: auto;
  flex: 1 1 20%;
  padding: 0 0.5% 0 0.5%;
}

.FlexRow,
footer {
  flex: 1 1 100%;
}

.ProjectCarousel {
  display: inline-flex;
  flex-basis: 100%;
}

/* ... General Themeing ... */
html {
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: grey;
}

div {
  color: whitesmoke;
}

.AreaWithMargin {
  margin-top: 1.25%;
  margin-right: 2.5%;
  margin-bottom: 1.25%;
  margin-left: 2.5%;
}

.AreaWithPadding {
  padding-left: 1.25%;
  padding-right: 1.25%;
  padding-top: 1.25%;
  padding-bottom: 1.25%;
}

.GithubTile {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}


h1 {
  padding-top: 0.5em;
}

h1,
h2,
h3 {
  color: white;
  /* background-image: linear-gradient(315deg, #F5F7FA 0%, #B8C6DB 74%); */
  /* display: inline-block;
  float: left;
  clear: both; */
  /* background-position: 50% 50%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  background-repeat: no-repeat;
  background-size: cover; */
}

h1.dark,
h2.dark,
h3.dark {
  color: black;
  /* background-image: linear-gradient(315deg, #F5F7FA 0%, #B8C6DB 74%); */
  /* display: inline-block;
  float: left;
  clear: both; */
  /* background-position: 50% 50%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  background-repeat: no-repeat;
  background-size: cover; */
}

kbd {
  font-size: 1.25em;
  color: rgb(38, 38, 38);
  background-image: linear-gradient(315deg, #D7E1EC 0%, #FFFFFF 100%);
}

kbd:before {
  content: "[";
}

kbd:after {
  content: "]";
}

footer :target {
  background: whitesmoke;
  color: black;
}