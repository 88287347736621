/* Links ... */
a:link {
    text-decoration-line: unset;
    /* color: rgb(255, 250, 250);  */
    color: inherit;
}

a:visited {
    /* color: rgb(255, 250, 250);  */
    color: inherit;
}

a:hover {
    text-decoration-line: underline;
    animation: blinker 1s ease-in-out infinite;
}

a:active {
    color: red;
}

/* https://stackoverflow.com/questions/16344354/how-to-make-blinking-flashing-text-with-css-3 */
@keyframes blinker {
    50% {
        opacity: 0;
    }
}